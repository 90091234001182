
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { BGState } from "@/store/BGState";
import GradationBGTrigger from "./GradationBGTrigger.vue";

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
  },
  props: {},
})
export default class Footer extends Vue {
  bgState = BGState.FOOTER;
  created() {}
}
