
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";

type TSize = "xs" | "s" | "md" | "l" | "xl";

enum ESizeRemVar {
  XS = "--font-size-rem__xs",
  S = "--font-size-rem__s",
  MD = "--font-size-rem__md",
  L = "--font-size-rem__l",
  XL = "--font-size-rem__xl",
}

@Options({
  props: {
    size: { type: String as PropType<TSize>, default: () => "s" },
    bold: { type: Boolean, default: () => true },
    shouldAdjust: { type: Boolean, default: () => false },
  },
})
export default class Typography extends Vue {
  private size!: TSize;
  private bold!: boolean;
  private shouldAdjust!: boolean;

  private get fontSizeValue() {
    let baseSize: ESizeRemVar | null = null;

    switch (this.size) {
      case "xs":
        baseSize = ESizeRemVar.XS;
        break;
      case "s":
      default:
        baseSize = ESizeRemVar.S;
        break;
      case "md":
        baseSize = ESizeRemVar.MD;
        break;
      case "l":
        baseSize = ESizeRemVar.L;
        break;
      case "xl":
        baseSize = ESizeRemVar.XL;
        break;
    }
    return baseSize;
  }

  get style() {
    return {
      fontSize: this.shouldAdjust
        ? `var(${this.fontSizeValue}__big, var(${this.fontSizeValue}))`
        : `var(${this.fontSizeValue})`,
      fontWeight: this.bold ? "bold" : null,
    };
  }
}
