
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Prop, Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { Mutation } from "vuex-class";
import GradationBGTrigger from "./GradationBGTrigger.vue";
import { BGState } from "@/store/BGState";

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
  },
})
export default class ServiceContents extends Vue {
  bgState = BGState.SERVICE;
  private contentWrapper!: HTMLElement;
  private contentFixed!: HTMLElement;
  showedService: "cosme" | "food" | "goods" = "cosme";

  @Prop()
  serviceGenres!: string[];

  @Prop()
  serviceMsg!: string;
}
