
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { Mutation } from "vuex-class";
import { BGState } from "@/store/BGState";
import GradationBGTrigger from "./GradationBGTrigger.vue";

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
  },
  props: {},
})
export default class Price extends Vue {
  bgState = BGState.PRICE;
  private contentWrapper!: HTMLElement;
  private contentFixed!: HTMLElement;
  private el!: HTMLElement;

  mounted() {
    this.contentWrapper = this.$refs.contentWrapper as HTMLElement;
    this.contentFixed = this.$refs.contentFixed as HTMLElement;
    this.el = this.$el as HTMLElement;
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  private handleScroll() {
    const viewportHeight = window.innerHeight;
    const elementRect = this.contentWrapper.getBoundingClientRect();
    const elementTop = elementRect.top;
    const elementBottom = elementRect.bottom;
    const elementWidth = elementRect.width;
    const fixedHeight = viewportHeight / 4;
    const fixedElRect = this.contentFixed.getBoundingClientRect();
    const fixedElBottom = fixedElRect.bottom;
    const fixedElHeight = fixedElRect.height;
    const fixedElWidth = fixedElRect.width;

    let translatePercent: number;
    translatePercent = elementTop / (viewportHeight - fixedHeight);
    translatePercent = translatePercent < 0 ? 0 : translatePercent > 1 ? 1 : translatePercent;
    this.contentFixed.style.transform = `translateX(${
      translatePercent * fixedElWidth
    }px)`;
    this.contentFixed.style.opacity = `${(1 - translatePercent)**2}`;

    if (elementTop > fixedHeight) {
      this.contentFixed.style.position = "absolute";
      this.contentFixed.style.top = "0";
      this.contentFixed.style.bottom = "";
    } else if (
      fixedElBottom >= elementBottom &&
      this.contentFixed.style.position != "absolute"
    ) {
      this.contentFixed.style.position = "absolute";
      this.contentFixed.style.top = "";
      this.contentFixed.style.bottom = `0`;
    } else if (
      elementTop <= fixedHeight &&
      fixedHeight + fixedElHeight <= elementBottom
    ) {
      this.contentFixed.style.position = "fixed";
      this.contentFixed.style.top = `${fixedHeight}px`;
      this.contentFixed.style.width = `${elementWidth}px`;
    }
  }
}
