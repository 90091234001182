
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { BGState } from "@/store/BGState";

@Options({
  components: { Typography, AppWidthContainer, TitleAlt, ContentContainer },
  props: {},
})
export default class About extends Vue {
  bgState = BGState.ABOUT;
  created() {}
}
