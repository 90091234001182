import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/img--comlab-logo__white.svg'
import _imports_1 from '../assets/img--comlab-logo__default.svg'


const _withScopeId = n => (_pushScopeId("data-v-620ae519"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header--row" }
const _hoisted_3 = { class: "header--col" }
const _hoisted_4 = { class: "header--logo" }
const _hoisted_5 = {
  key: 0,
  class: "img-fluid",
  src: _imports_0
}
const _hoisted_6 = {
  key: 1,
  class: "img-fluid",
  src: _imports_1
}
const _hoisted_7 = { class: "header--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppWidthContainer = _resolveComponent("AppWidthContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppWidthContainer, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_link, { to: { name: 'home' } }, {
                default: _withCtx(() => [
                  (_ctx.gradeBGIsDark)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                    : (_openBlock(), _createElementBlock("img", _hoisted_6))
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[0] || (_cache[0] = () => _ctx.onClickMenuBtn('About'))
            }, " コム・ラボについて "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[1] || (_cache[1] = () => _ctx.onClickMenuBtn('Service'))
            }, " 事業紹介 "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[2] || (_cache[2] = () => _ctx.onClickMenuBtn('Price'))
            }, " 料金例 "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[3] || (_cache[3] = () => _ctx.onClickMenuBtn('Cases'))
            }, " 事例商品紹介 "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[4] || (_cache[4] = () => _ctx.onClickMenuBtn('Message'))
            }, " 代表メッセージ "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[5] || (_cache[5] = () => _ctx.onClickMenuBtn('Flow'))
            }, " 商品開発の流れ "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[6] || (_cache[6] = () => _ctx.onClickMenuBtn('Company'))
            }, " 会社情報 "),
            _createElementVNode("div", {
              class: "header--cell",
              onClick: _cache[7] || (_cache[7] = () => _ctx.onClickMenuBtn('Contact'))
            }, " お問い合わせ ")
          ])
        ])
      ]),
      _: 1
    })
  ]))
}