
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { Mutation } from "vuex-class";
import GradationBGTrigger from "./GradationBGTrigger.vue";
import { BGState } from "@/store/BGState";
import ServiceContents from "./ServiceContents.vue";

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
    ServiceContents,
  },
  props: {},
})
export default class Service extends Vue {
  bgState = BGState.SERVICE;
  private contentWrapper!: HTMLElement;
  private contentFixed!: HTMLElement;
  showedService: "cosme" | "food" | "goods" = "cosme";
  menuBarInnerStyle = { height: "0%" };

  serviceGenres = {
    cosme: [
      "化粧水（ローション）",
      "保湿クリーム",
      "美容液",
      "石鹸",
      "洗顔",
      "クレンジング",
      "乳液",
      "フェイスオイル",
      "UVケア",
      "ヘアケア",
    ],
    food: [
      "睡眠改善",
      "糖尿病",
      "ダイエット",
      "栄養補助",
      "健康増進",
      "眼精疲労",
      "コラーゲン",
      "ヒアルロン酸",
      "錠剤",
      "ゼリー",
      "粉末",
      "飲料",
    ],
    goods: [
      "スプレー",
      "ヘアブラシ",
      "ペット用品",
      "エアゾール (一発噴射剤)",
      "消臭",
      "除菌",
      "洗剤",
      "ペットシャンプー",
    ],
  };

  serviceMsg = {
    cosme: `高品質で安全な原料を使用<br />
            国内・国外（アジア圏）で展開<br />
            国外では高級ブランドとして定番化`,
    food: `ナチュラルソリューション自然素材に着目<br />
美容や健康の悩みをサポートする商品開発<br />
メイド イン ジャパンの品質感で国外に展開`,
    goods: `一般雑貨を機能性商品に<br />
付加価値商品として新定番にアップデート`,
  };

  get showedServiceMsg() {
    return this.serviceMsg[this.showedService];
  }

  get showedServiceGenres() {
    return this.serviceGenres[this.showedService];
  }

  mounted() {
    this.contentWrapper = this.$refs.contentWrapper as HTMLElement;
    this.contentFixed = this.$refs.contentFixed as HTMLElement;
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  private handleScroll() {
    const viewportHeight = window.innerHeight;
    const elementRect = this.contentWrapper.getBoundingClientRect();
    const elementTop = elementRect.top;
    const elementBottom = elementRect.bottom;
    const elementHeight = elementRect.height;
    const elementWidth = elementRect.width;
    const fixedHeight = viewportHeight / 4;

    if (
      elementTop <= fixedHeight
      // elementTop + elementHeight >= viewportHeight / 2
    ) {
      this.contentFixed.style.position = "fixed";
      this.contentFixed.style.top = `${fixedHeight}px`;
      this.contentFixed.style.width = `${elementWidth}px`;
      this.contentFixed.style.bottom = "";
    } else {
      this.contentFixed.style.position = "";
      this.contentFixed.style.top = "";
      this.contentFixed.style.bottom = "";
      this.contentFixed.style.transform = "";
    }

    const grid = elementHeight / 2.3;

    if (fixedHeight - elementTop > grid * 1.5) {
      this.showedService = "goods";
    } else if (fixedHeight - elementTop > grid * 0.7) {
      this.showedService = "food";
    } else {
      this.showedService = "cosme";
    }

    let scrollPer = (fixedHeight - elementTop) / elementHeight;
    scrollPer = scrollPer < 0 ? 0 : scrollPer > 1 ? 1 : scrollPer;
    this.menuBarInnerStyle.height = `${scrollPer * 100}%`;

    if (
      elementBottom <= this.contentFixed.getBoundingClientRect().bottom
      // elementTop + elementHeight >= viewportHeight / 2
    ) {
      this.contentFixed.style.position = "absolute";
      this.contentFixed.style.top = "";
      this.contentFixed.style.bottom = "0px";
    }
  }
}
