
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Prop, Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import { State, Getter, Mutation, Action } from "vuex-class";
import { TBGState } from "@/store/BGState";

@Options({
  components: { Typography, AppWidthContainer },
  props: {},
})
export default class TitleAlt extends Vue {
  created() {}
  @Getter activeBGState!: TBGState;

  @Prop()
  isDefaultColor?: boolean;

  get gradeBGIsDark() {
    return !this.isDefaultColor && this.activeBGState.option?.isDark;
  }
}
