export type TBGStateKey =
  | "TOP"
  | "ABOUT"
  | "SERVICE"
  | "PRICE"
  | "CASES"
  | "MESSAGE"
  | "FLOW"
  | "COMPANY"
  | "CONTACT"
  | "FOOTER";
export type TBGStateOption = { color: string; isDark: boolean } | null;
export type TBGState = { order: number; option: TBGStateOption };

export const BGState: {
  [key in TBGStateKey]: TBGState;
} = {
  TOP: { order: 0, option: null },
  ABOUT: { order: 1, option: null },
  SERVICE: { order: 2, option: { color: "#262626", isDark: true } },
  PRICE: { order: 3, option: { color: "#CDCDCD", isDark: false } },
  CASES: { order: 4, option: { color: "#eee", isDark: false } },
  MESSAGE: { order: 5, option: { color: "#fff", isDark: false } },
  FLOW: { order: 6, option: { color: "#b55454", isDark: true } },
  COMPANY: { order: 7, option: { color: "#eee", isDark: false } },
  CONTACT: { order: 8, option: { color: "#eee", isDark: false } },
  FOOTER: { order: 9, option: { color: "#eee", isDark: false } },
};
