
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { Mutation } from "vuex-class";
import { BGState } from "@/store/BGState";
import GradationBGTrigger from "./GradationBGTrigger.vue";

interface ICase {
  id: number;
  name: string;
  genre: string;
  productName: string;
  func: string;
  cellImg: string;
  img: string;
}

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
  },
  props: {},
})
export default class Cases extends Vue {
  bgState = BGState.CASES;
  private contentWrapper!: HTMLElement;
  private contentFixed!: HTMLElement;
  private el!: HTMLElement;
  cases: ICase[] = [
    {
      id: 1,
      name: "美かさね アクアリッチモイストゲル",
      genre: "化粧品",
      productName: "オールインワン型ゲルクリーム",
      func: `しっとりなのにべたつかない うるおい続くオールインワンゲル<br><br>
浸透力の高い機能性酸素水(ベースの水) 角質層まで肌にうるおいを与える Wヒアルロン酸 Wコラーゲン Wナノ化加工<br><br>
全成分: 水、トリ(カプリル酸・カプリン酸)
グリセリル、BG、グリセリン、ペンチレングリコール、ステアリン酸グリセリル、カルボマー、
ジメチコン、水酸化K、ポリソルベート60、キサンタンガム、イソノナン酸イソトリデシル、
トコフェロールグリチルリチン酸2K、水添レシチン、ジラウロイルグルタミン酸リシン、マカデミア種子油、メドウフォーム油、ヒアルロン酸Na、アッケシソウエキス、コメ胚芽油、
ヘーゼルナッツ油、加水分解エラスチン、オタネニンジン根エキス、シア油、加水分解コラーゲン、アボカド油、ホホバ種子油、ツバキ種子油、ブドウ種子油、アーモンド油、
アセチルヒアルロン酸Na、月見草油、カニナバラ果実油、水溶性コラーゲン、サクシノイルアテロコラーゲン、炭酸水素Na、セルロースガム、白金、
フェノキシエタノール`,
      cellImg: require("@/assets/img--case-bikasane__thumb.png"),
      img: require("@/assets/img--case-bikasane.png"),
    },
    {
      id: 2,
      name: "除菌バスター",
      genre: "健康雑貨",
      productName: "一発噴射型除菌剤",
      func: `6〜18畳までの密閉空間専用の空間まるごと光触媒コーティング剤<br><br>
      家具、壁、窓、浴室、車内等の除菌および消臭<br><br>
全成分: 水、アパタイト被覆参加チタン、エタノール`,
      cellImg: require("@/assets/img--case-jyokin__thumb.png"),
      img: require("@/assets/img--case-jyokin.png"),
    },
    {
      id: 3,
      name: "グルメスリム",
      genre: "健康食品",
      productName: "青花加工食品",
      func: `おいしいものを食べながら、糖質制限も無理な運動もナシ<br><br>
      滋賀県産アオバナに含まれるイミノ糖が、食後の糖 の吸収を抑える効果があります。おすすめの飲み方は、糖質や脂質の多いお食事の前に水またはお湯などで溶かしてお飲みください。生活習慣病の予防やダイエットに継続して摂取していただくことをお勧めします。<br><br>

全成分: アオバナ粉末 (国内製造)、抹茶、ヨモギ末 1.5g 30包入り`,
      cellImg: require("@/assets/img--case-gourmet__thumb.png"),
      img: require("@/assets/img--case-gourmet.png"),
    },
  ];

  currentCase: ICase = this.cases[0];

  mounted() {
    this.contentWrapper = this.$refs.contentWrapper as HTMLElement;
    this.contentFixed = this.$refs.contentFixed as HTMLElement;
    this.el = this.$el as HTMLElement;
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  private handleScroll() {
    const viewportHeight = window.innerHeight;
    const elementRect = this.contentWrapper.getBoundingClientRect();
    const elementTop = elementRect.top;
    const elementBottom = elementRect.bottom;
    const elementWidth = elementRect.width;
    const fixedHeight = viewportHeight / 4;
    const fixedElRect = this.contentFixed.getBoundingClientRect();
    const fixedElBottom = fixedElRect.bottom;
    const fixedElHeight = fixedElRect.height;
    const fixedElWidth = fixedElRect.width;

    let translatePercent: number;
    translatePercent = elementTop / (viewportHeight - fixedHeight);
    translatePercent =
      translatePercent < 0 ? 0 : translatePercent > 1 ? 1 : translatePercent;
    this.contentFixed.style.transform = `translateX(${
      translatePercent * fixedElWidth
    }px)`;
    this.contentFixed.style.opacity = `${(1 - translatePercent) ** 2}`;

    if (elementTop > fixedHeight) {
      this.contentFixed.style.position = "absolute";
      this.contentFixed.style.top = "0";
      this.contentFixed.style.bottom = "";
    } else if (
      fixedElBottom >= elementBottom &&
      this.contentFixed.style.position != "absolute"
    ) {
      this.contentFixed.style.position = "absolute";
      this.contentFixed.style.top = "";
      this.contentFixed.style.bottom = `0`;
    } else if (
      elementTop <= fixedHeight &&
      fixedHeight + fixedElHeight <= elementBottom
    ) {
      this.contentFixed.style.position = "fixed";
      this.contentFixed.style.top = `${fixedHeight}px`;
      this.contentFixed.style.width = `${elementWidth}px`;
    }
  }
}
