
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { BGState } from "@/store/BGState";
import GradationBGTrigger from "./GradationBGTrigger.vue";
import FlowRow from "./FlowRow.vue";

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
    FlowRow,
  },
  props: {},
})
export default class Flow extends Vue {
  pointBG!: HTMLElement;
  mounth1!: HTMLElement;
  mounth2!: HTMLElement;
  bgState = BGState.FLOW;
  flows: { stepNumber: number | null; title: string; desc: string }[] = [
    {
      stepNumber: 1,
      title: "立ち上げの打ち合わせ",
      desc: "商品名、形状、数量、容量、コンセプト、顧客ターゲット、販路などを確認",
    },
    {
      stepNumber: null,
      title: "開発着手金のお支払い",
      desc: "10万円 (納品時のお支払いで割引いて精算いたします)",
    },
    {
      stepNumber: 2,
      title: "初回商品見本の提出",
      desc: "使用感テスト用見本、容器見本、パッケージ見本、パッケージデザイン案など",
    },
    {
      stepNumber: 3,
      title: "試用テスト / 意見交換",
      desc: "使用感、容量など商品仕様の確認、商品検索による商標確認など",
    },

    {
      stepNumber: 4,
      title: "再調整",
      desc: "内容・容器・デザイン・商品名などの再調整",
    },
    {
      stepNumber: 5,
      title: "仕様の決定･確認",
      desc: "内容・容器・デザイン・商品名の決定",
    },
    {
      stepNumber: null,
      title: "製造前受金のお支払い",
      desc: "製造代金の一部をお支払いしていただきます",
    },
    {
      stepNumber: 6,
      title: "本生産",
      desc: "決定した内容で製品の本生産を開始",
    },
    {
      stepNumber: null,
      title: "納品時のお支払い",
      desc: "残りの開発・製造代金をお支払いしていただきます",
    },
    {
      stepNumber: 7,
      title: "商品の納品",
      desc: "生産が完了した商品の納品",
    },
  ];

  created() {}
  mounted() {
    this.pointBG = this.$refs.pointBG as HTMLElement;
    this.mounth1 = this.$refs.mounth1 as HTMLElement;
    this.mounth2 = this.$refs.mounth2 as HTMLElement;
    this.setPointBG();
    this.setMounth();
  }

  setPointBG() {
    const pointEls = this.$el.getElementsByClassName("point") as HTMLCollection;
    const pointPosX = pointEls[0].getBoundingClientRect().x;
    const pointPosYFirst = pointEls[0].getBoundingClientRect().y;
    const pointPosYLast =
      pointEls[pointEls.length - 1].getBoundingClientRect().y;
    this.pointBG.style.left = `${pointPosX}px`;
    this.pointBG.style.height = `${pointPosYLast - pointPosYFirst + 50}px`;
  }

  setMounth() {
    // const pointEls = this.$el.getElementsByClassName("point") as HTMLCollection;
    // const pointPosYFirst = pointEls[0].getBoundingClientRect().y;
    // const pointPosYLast =
    //   pointEls[pointEls.length - 1].getBoundingClientRect().y;
    // this.pointBG.style.left = `${pointPosX}px`;
    // this.pointBG.style.height = `${pointPosYLast - pointPosYFirst + 50}px`;
  }
}
