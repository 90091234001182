import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ isBigFont: _ctx.isBigFont, gradeBGIsDark: _ctx.gradeBGIsDark })
  }, [
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (!_ctx.loaded)
          ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_router_view),
    _createVNode(_component_Header, { class: "header" })
  ], 2))
}