import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f84dff7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["TitleAlt", { gradeBGIsDark: _ctx.gradeBGIsDark }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "sub", {}, undefined, true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}