
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Prop, Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { BGState } from "@/store/BGState";
import GradationBGTrigger from "./GradationBGTrigger.vue";

@Options({
  components: {
    Typography,
    AppWidthContainer,
    TitleAlt,
    ContentContainer,
    GradationBGTrigger,
  },
  props: {},
})
export default class FlowRow extends Vue {
  @Prop()
  stepNumber!: number | null;

  @Prop()
  flowTitle!: string;

  @Prop()
  flowDesc!: string;

  created() {}

  get unShowStep() {
    return this.stepNumber == null;
  }
}
