
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";

@Options({
  components: { Typography, AppWidthContainer, TitleAlt, ContentContainer },
  props: {},
})
export default class Loading extends Vue {
  noscroll(e: Event) {
    e.preventDefault();
  }

  mounted() {
    document.addEventListener("touchmove", this.noscroll, { passive: false });
    document.addEventListener("wheel", this.noscroll, { passive: false });
  }

  beforeUnmount() {
    document.removeEventListener("touchmove", this.noscroll);
    document.removeEventListener("wheel", this.noscroll);
  }
}
