
import Header from "@/components/Header.vue";
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/Footer.vue";
import Typography from "@/components/Typography.vue";
import { State, Getter, Mutation, Action } from "vuex-class";
import { TBGState } from "./store/BGState";
import Loading from "./components/Loading.vue";

@Options({
  components: { Typography, Footer, Header, Loading },
})
export default class App extends Vue {
  isBigFont = false;
  @Getter activeBGState!: TBGState;
  @State loaded!: boolean;

  get gradeBGIsDark() {
    return this.activeBGState.option?.isDark;
  }

  toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  mounted() {}
}
