import { createStore } from "vuex";
import { BGState, TBGState } from "./BGState";

export default createStore({
  state: {
    bgStates: [BGState.TOP] as TBGState[],
    loaded: false,
  },
  getters: {
    activeBGState: (state) => {
      let biggestOrderState: TBGState = BGState.TOP;

      state.bgStates.forEach((v) => {
        if (biggestOrderState.order < v.order) {
          biggestOrderState = v;
        }
      });

      return biggestOrderState;
    },
  },
  mutations: {
    onLoaded: (state) => {
      state.loaded = true;
    },
    addBGState: (state, prop: TBGState) => {
      if (state.bgStates.find((v) => v == prop)) return;
      state.bgStates.push(prop);
    },
    removeBGState: (state, prop: TBGState) => {
      const newStates = state.bgStates.map((v) => {
        if (v == prop) {
          return null;
        } else {
          return v;
        }
      });

      state.bgStates = newStates.filter((v) => v != null) as TBGState[];
    },
  },
  actions: {},
  modules: {},
});
