
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import Eyecatch from "@/components/Eyecatch.vue";
import About from "@/components/About.vue";
import Service from "@/components/Service.vue";
import GradationBG from "@/components/GradationBG.vue";
import Price from "@/components/Price.vue";
import Message from "@/components/Message.vue";
import Flow from "@/components/Flow.vue";
import Company from "@/components/Company.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import Cases from "@/components/Cases.vue";

@Options({
  components: {
    Typography,
    Eyecatch,
    About,
    Service,
    GradationBG,
    Price,
    Message,
    Flow,
    Contact,
    Company,
    Footer,
    Cases,
  },
})
export default class HomeView extends Vue {}
