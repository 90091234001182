
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Prop, Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { State, Getter, Mutation, Action } from "vuex-class";
import { BGState, TBGState } from "@/store/BGState";

@Options({
  components: { Typography, AppWidthContainer, TitleAlt, ContentContainer },
})
export default class GradationBGTrigger extends Vue {
  private observableElement!: HTMLElement;
  private observer!: IntersectionObserver;
  @Mutation addBGState!: (prop: TBGState) => void;
  @Mutation removeBGState!: (prop: TBGState) => void;

  @Prop()
  bgState!: TBGState;

  mounted() {
    this.observableElement = this.$el as HTMLElement;
    this.createObserver();
  }

  beforeUnmount() {
    this.observer.disconnect();
  }

  private createObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.addBGState(this.bgState);
          } else {
            this.removeBGState(this.bgState);
          }
        });
      },
      {
        threshold: 0, // Adjust this value to control the percentage of the element visible before triggering the callback
      }
    );

    this.observer.observe(this.observableElement);
  }
}
