
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import TitleAlt from "./TitleAlt.vue";
import ContentContainer from "./ContentContainer.vue";
import { State, Getter, Mutation, Action } from "vuex-class";
import { TBGState } from "@/store/BGState";

@Options({
  components: { Typography, AppWidthContainer, TitleAlt, ContentContainer },
  props: {},
})
export default class GradationBG extends Vue {
  @Getter activeBGState!: TBGState;

  get gradeBG() {
    return this.activeBGState.option != null;
  }

  created() {}
}
