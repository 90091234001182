
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import { Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import { Mutation } from "vuex-class";

@Options({
  components: { Typography, AppWidthContainer },
  props: {},
})
export default class Eyecatch extends Vue {
  @Mutation onLoaded!: () => void;
  video: HTMLVideoElement | null = null;

  created() {
  }
  
  mounted() {
    this.video = document.getElementById("myVideo") as HTMLVideoElement;
    this.video.onloadeddata = () => {
      this.onLoaded();
    }
  }
}
