
import { Options, Vue } from "vue-class-component";
import Typography from "@/components/Typography.vue";
import router from "@/router";
import { Prop, Watch } from "vue-property-decorator";
import AppWidthContainer from "./AppWidthContainer.vue";
import { Getter } from "vuex-class";
import { TBGState } from "@/store/BGState";

@Options({
  components: { Typography, AppWidthContainer },
  props: {},
})
export default class Header extends Vue {
  @Getter activeBGState!: TBGState;

  @Prop()
  isDefaultColor?: boolean;

  get gradeBGIsDark() {
    return !this.isDefaultColor && this.activeBGState.option?.isDark;
  }

  isOpenMenu = false;

  @Watch("isOpenMenu")
  onChangeIsOpenMenu(v: boolean) {
    if (v) {
      document.addEventListener("touchmove", this.handleMove, {
        passive: false,
      });
      document.addEventListener("mousewheel", this.handleMove, {
        passive: false,
      });

      window.scrollTo(0, 0);
    } else {
      document.removeEventListener("touchmove", this.handleMove);
      document.removeEventListener("mousewheel", this.handleMove);
    }
  }

  handleMove(e: Event) {
    e.preventDefault();
  }

  onClickMenuBtn(id: string) {
    const targetElTop =
      document.getElementById(id)?.getClientRects()[0].top ?? 0;
    window.scrollTo({ top: targetElTop + window.pageYOffset });
    console.log(targetElTop);
  }

  created() {
    router.afterEach(() => {
      this.isOpenMenu = false;
    });
  }
}
