import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4977a5e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "HomeView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Eyecatch = _resolveComponent("Eyecatch")!
  const _component_About = _resolveComponent("About")!
  const _component_GradationBG = _resolveComponent("GradationBG")!
  const _component_Service = _resolveComponent("Service")!
  const _component_Price = _resolveComponent("Price")!
  const _component_Cases = _resolveComponent("Cases")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Flow = _resolveComponent("Flow")!
  const _component_Company = _resolveComponent("Company")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Eyecatch),
    _createVNode(_component_About),
    _createVNode(_component_GradationBG),
    _createVNode(_component_Service),
    _createVNode(_component_Price),
    _createVNode(_component_Cases),
    _createVNode(_component_Message),
    _createVNode(_component_Flow),
    _createVNode(_component_Company),
    _createVNode(_component_Contact),
    _createVNode(_component_Footer)
  ]))
}